/* SidebarStyles.css */

/* Add some transition for smooth color change and border effects */
/* .ant-menu-item {
    transition: background-color 0.3s ease, border 0.3s ease;
    border: 2px solid transparent; 
    border-radius: 8px;
    margin: 5px 0; 
    padding: 10px 15px; 
} */

/* Hover effect for sidebar menu items */
/* .ant-menu-item:hover {
    background-color: #f5f5f5;  
    border-color: #dcdcdc;  
} */

/* Active/Clicked effect for sidebar menu items */
/* .ant-menu-item-selected {
    background-color: #dcdcdc;
    border-color: #a0a0a0;
    color: #000;
    position: relative;
} */

/* Add a left-side bar for the active menu item */
/* .ant-menu-item-selected::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: #000; 
} */

/* .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item, .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 35px !important;
} */

/* Change the color of icons or other elements inside the menu item on hover or when selected */
/* .ant-menu-item:hover .anticon, .ant-menu-item-selected .anticon {
    color: #000; } */
/* styles.css */
.menu-item-hover:hover {
  background-color: #113d7f !important;
  height: 100% !important;
}

.inihuruf {
  /* background-color: red !important; */
  text-decoration: none;
}
.menu-item-hover:hover span {
  color: white !important;
  height: 100% !important;
  text-decoration-color: white !important;
}
.menu-item-active span {
  color: white;
  text-decoration-color: white !important;
  height: 100% !important;
}

.menu-item-active {
  background-color: #113d7f !important;
  height: 100% !important;
  margin-top: 10px !important;
}

.ant-menu-item a::before {
  display: flex;
  align-items: center;
  height: 100% !important;
}

.untuk-div {
  display: flex !important;
  align-items: center !important;
}

.untuk-icons {
  font-size: 30px !important;
  color: white;
  margin-bottom: 8px !important;
  margin-top: 10px !important;
}

.untuk-span {
  font-weight: bold;
  color: black;
  margin-bottom: 10px !important;
  margin-left: 8px !important;
}

.ant-menu-item-selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  background-color: #000;
  height: 30px !important;
}

.ant-menu-item {
  /* transition: background-color 0.3s ease, border 0.3s ease; */
  border: 2px solid transparent;
  border-radius: 8px;
  height: 100% !important;
}

.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 0px !important;
}

.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 40px !important;
  margin-left: 5px !important;
}

.ant-menu-item-selected::before {
  background-color: transparent !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: transparent !important;
}
