.container {
  max-width: 100%;
}

.position-absolute {
  position: absolute;
}

.nowrap {
  white-space: nowrap;
}

.ontop {
  vertical-align: top;
}

.mx-2 {
  padding: 0 10px 0 10px;
}

hr.new2 {
  border-top: 1px dashed #000;
}

@page {
  /* size: A4; */
  /* margin: 0; */
  /* margin-top: 20px; */
}
body, .print-one-body, .print-one, .print-one .table-bordered th, .print-one .table-bordered td, .print-one p, .print-one h2, .print-one h1, .print-one h3, .print-one h4, .print-one h5, .print-one h6 {
  font-family: Arial, sans-serif;
  color: #000000;
}