@media print {
  .print-one {
    page-break-inside: avoid;
    overflow: hidden;
  }

  .print-one .d-flex {
    display: flex;
    justify-content: space-between;
  }

  .print-one .table {
    page-break-inside: avoid;
  }

  .print-one .table-bordered {
    border-collapse: collapse;
    width: 100%;
  }

  .print-one .table-bordered th, .print-one .table-bordered td {
    border: 2px solid black;
  }

  .print-one-body {
    margin: 0;
    padding: 0;
    width: auto; /* Adjust width to account for margins */
    height: auto; /* Adjust height to account for margins */
  }

  @page {
    size: A4;
    margin: 30px; /* Set uniform margins */
  }

  .container {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .row {
    margin: 0;
  }

  .col {
    padding: 0;
  }
}

body, .print-one-body, .print-one, .print-one .table-bordered th, .print-one .table-bordered td, .print-one p, .print-one h2, .print-one h1, .print-one h3, .print-one h4, .print-one h5, .print-one h6 {
  font-family: Arial, sans-serif;
  color: #000000;
}
