@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.h5,
h5 {
  font-size: 1.25rem;
  color: #1A5CBF;
  font-family: 'Montserrat', sans-serif;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.h6,
h6 {
  font-family: 'NoirPro';
}

.ant-select {
  font-family: 'NoirPro';
}

.ant-select-selection-item {
  font-family: 'NoirPro';
}

.ant-table-cell {
  font-family: 'NoirPro';
}

.ant-picker-input>input {
  font-family: 'NoirPro';
}

.dQuovt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'NoirPro';
}

.jUmlQv div:first-child {
  white-space: nowrap;
  overflow: hidden;
  font-family: 'NoirPro';
  text-overflow: ellipsis;
}

.ant-input {
  font-family: 'NoirPro';
}

.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  margin-top: 5px;
  font-family: 'NoirPro';
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  font-family: 'NoirPro';
}

.btn.btn-primary.btn-sm {
  font-family: 'NoirPro';
}

.menu-item-hover:hover {
  background-color: #1a5cbf !important;
  color: white !important;
  border-radius: 10px !important;
  width: 90% !important;
}

.menu-item-active {
  background-color: #1a5cbf !important;
  color: white !important;
  border-radius: 10px !important;
  width: 90% !important;


}

.form-label {
  color: black;
  margin-bottom: 0.5rem;
  font-family: 'NoirPro';
}


.ant-input[disabled] {
  color: black;
  background-color: aliceblue;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  font-family: 'NoirPro';
}

.ant-layout-sider .ant-menu-inline .ant-menu-item {
  padding-left: 20px !important;
  margin-block: 10px;
  margin-left: 10px;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: #f5f5f5;
}

.ant-modal-title {
  margin: 0;
  color: #1A5CBF;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
}

/* .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s;
  font-family: 'NoirPro';
  margin-top: 10px;
} */

.ant-table-thead>tr>th {
  position: relative;
  color: white;
  font-weight: 500;
  text-align: left;
  background: #1A5CBF !;
  border-bottom: 1px solid #e8e8e8;
  font-family: 'NoirPro';
  /* transition: background 0.3s ease; */
}

.td,
td {
  border: "1px solid black";
  padding: "10px"
}

.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}

.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 20px;
}

.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 0px;
}

.gx-layout-sider-header {
  padding: 10px 30px 10px 70px;
  height: 72px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
  background-color: white;
}


.iTbKAT {
  display: inline-flex;
  align-items: center;
  justify-content: inherit;
  height: 100%;
  width: 100%;
  outline: none;
  user-select: none;
  overflow: hidden;
  background-color: #1A5CBF;
  color: white;
}

.khKJpi {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 10px;
  background-color: #1A5CBF;
  color: white;
}

.table-head th {
  background-color: lightblue;
  /* Tambahkan gaya lain sesuai kebutuhan Anda */
}

.ant-table-thead>tr>th {
  position: relative;
  color: white;
  font-weight: 500;
  text-align: left;
  background: #1A5CBF;
  border-bottom: 1px solid #e8e8e8;
  /* transition: background 0.3s ease; */
}

/* Contoh gaya khusus untuk kolom "Kode Tarif" */

/* .ant-select {
  box-sizing: border-box;
  margin: 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0;
  color: blue;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
} */

/* .input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border-block-color: blue;
  border-inline-color: blue;
} */

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
  /* border-block-end-color: blue; */
  border-block-color: blue;
  border-inline-color: blue;
}

/* Font untuk label */
.ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 36px;
  color: #535353;
  font-size: 14px;
  font-family: 'NoirPro';
}

.ant-btn {
  font-family: 'NoirPro';
}

.label {
  display: inline-block;
  font-family: 'NoirPro';
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}


.custom-button {
  width: 180px;
  color: white;
  border: none;
  transition: background-color 0.3s;
  background-color: #00a65a;
  /* Warna hijau saat tidak aktif */
  cursor: pointer;
}

.custom-button a {
  color: inherit;
  text-decoration: none;
}

.custom-button:hover {
  background-color: #00a65a;
  /* Warna hijau saat hover */
}


.text-dashboard {
  font-size: 30px;
  font-weight: bold;
}

.text-data {
  font-size: 25px;
}

.text-dashboard-card {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .text-data {
    font-size: 15px;
  }
}

/* Gaya teks ketika ukuran layar kecil */
@media screen and (max-width: 480px) {
  .text-data {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .text-dashboard {
    font-size: 20px;
  }
}

/* Gaya teks ketika ukuran layar kecil */
@media screen and (max-width: 480px) {
  .text-dashboard {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .text-dashboard-card {
    font-size: 15px;
  }
}

/* Gaya teks ketika ukuran layar kecil */
@media screen and (max-width: 480px) {
  .text-dashboard-card {
    font-size: 10px;
  }
}

/* @media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) ;
    background-color: #BAD6FF;
  }
 
/* } */


iframe {
  position: relative !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 2147483647;
}

@media (max-width: 600px) {
  .responsive-table {
    width: 100%;

  }
}